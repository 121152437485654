<template>
  <base-layout>
    <h1>О продукте</h1>
    <section class="product">
      <div class="product__wrapper">
        <div class="product__1">
          <img class="logo" src="../assets/img/superjuce_logo.png" alt="">
          <div class="green_text text">
            <span class="white_text">100% сок</span> из спелых фруктов и ягод <span class="white_text">без добавленного сахара</span> в удобной упаковке 200 мл с трубочкой. В состав входит <span class="white_text">витаминный комплекс Active Kid</span>, который даст ребенку энергию для развития и достижений
          </div>
        </div>
        <div class="product__1-slider">
          <agile :dots="true" :options="productsTwoOptions">
            <div v-for="item in carousel.productsJuice"  :key="item.key">
              <div class="product__item">
                <div>
                  <img class="product__1-image" :src="getPngImgUrl(item.image.src)" :alt="item.image.alt">
                  <p class="product__1-title">{{ item.caption }}</p>
                  <p class="product__1-text">{{ item.description }}</p>
                </div>
                <div class="vitamins">
                  <div style="margin-left: 5px;font-family: Roboto;font-size:20px;text-transform: none" class="">В одной порции ({{ item.ml }} мл.):</div>
                  <br>
                  <div :style="{ backgroundColor: subitem.color }" class="vitamin" v-for="subitem in item.vitamines" :key="subitem.caption">
                    <div :style="{ fontSize: subitem.fontSize }" class="vitamin-caption">
                <span style="display: block" v-for="(text, index) in subitem.caption.split('\n')" :key="index">
                  {{ text }}
                </span>
                    </div>
                    <div class="vitamin-value">
                      {{ subitem.value}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </agile>
        </div>
        <div class="product__2">
          <img class="logo" src="../assets/img/logo_super-drink.png" alt="">
          <div class="green_text text">
            Вкусный микс <span class="white_text">чистой артезианской воды и сока спелых фруктов и ягод</span> для утоления жажды со вкусом и пользой, который удобно взять с собой на прогулку или в школу благодаря упаковке с крышечкой (330 мл). В состав входит <span class="white_text">витаминный комплекс Active Kid</span>, который даст ребенку энергию для развития и достижений
          </div>
        </div>
        <div class="product__1-slider">
          <agile :dots="true" :options="productsTwoOptions">
            <div v-for="item in carousel.productsDrink" :key="item.key">
              <div class="product__item">
                <div>
                  <img class="product__1-image" :src="getPngImgUrl(item.image.src)" :alt="item.image.alt">
                  <p style="color: #1EB1ED" class="product__1-title">{{ item.caption }}</p>
                  <p class="product__1-text">{{ item.description }}</p>
                </div>
                <div class="vitamins">
                  <div style="margin-left: 5px;font-family: Roboto;font-size:20px;" class="">В одной порции ({{ item.ml }} мл.):</div>
                  <br>
                  <div :style="{ backgroundColor: subitem.color }" class="vitamin" v-for="subitem in item.vitamines" :key="subitem.caption">
                    <div :style="{ fontSize: subitem.fontSize }" class="vitamin-caption">
                <span style="display: block" v-for="(text, index) in subitem.caption.split('\n')" :key="index">
                  {{ text }}
                </span>
                    </div>
                    <div class="vitamin-value">
                      {{ subitem.value}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </agile>
        </div>
      </div>
      <div class="product__footer">
        <img class="logo" src="../assets/img/product_footer_logo.png" alt="">
        <img src="../assets/img/product_footer_stuff.png" alt="" class="arrows">
        <div class="green_text text_1">
          Обогащены специально<br>разработанным<br>комплексом <span class="white_text">Active Kid</span>
          <br>
          <br>
          <div class="text_2">
            В2, В3, В5, В12
            <br>
            <span style="font-size: 18px;">отвечают за <span class="white_text">укрепление</span> защитных сил организма, <span class="white_text">рост</span> мышц, <span class="white_text">работу</span> всех клеток и восполнение необходимой детям <span class="white_text">энергии</span></span>
          </div>
        </div>
      </div>
    </section>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout"
import { VueAgile } from 'vue-agile'

import {
  productsTwoOptions,
  carousel
} from '@/carousel/config'

export default {
  name: "Products",
  components: {
    agile: VueAgile,
    BaseLayout
  },

  data() {

    return {
      productsTwoOptions,
      carousel,
    }
  },

  methods: {
    getSvgImgUrl(image) {
      return require(`../assets/svg/${image}`)
    },

    getPngImgUrl(image) {
      return require(`../assets/img/${image}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.product__1-title {
  margin-left: 7%;
  color: $orange;
  font-size: 32px;
  max-width: 75%
}

.product__1-text {
  font-family: Roboto;
  margin-left: 7%;
  color: #000;
  font-size: 16px;
  max-width: 50%
}

a {
  text-decoration: none;
}

.wrapper {
  background-color: $green;
}

h1, h2, h3 {
  text-align: center;
}

h1 {
  font-size: 60px;
  font-weight: 500;
  color: $white;
}

.product__1-image {
  float: right;
  display: block;
  margin-right: 45px;
  margin-bottom: 20px;
  max-height: 400px;
}

.vitamins {
  position: absolute;
  width: 100%;
  bottom: 38px;
  left: 7%;
}

.vitamin {
  padding: 5px;
  display: inline-block;
  border-radius: 9px;
  margin: 0 5px;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
@media screen and (min-width: 993px) {
  .product {
    &::before, &::after {
      content: "";
      position: absolute;
      display: block;
      background-size: cover;
    }

    &::before {
      width: 90px;
      height: 100px;
      top: 120px;
      left: 10%;
      background-image: url("../assets/svg/label-yellow-thunderbolt.svg");
    }

    &::after {
      width: 100px;
      height: 95px;
      top: 120px;
      right: 10%;
      background-image: url("../assets/svg/label-heart.svg");
    }
  }
}


.product__1-text {
  font-size: 20px;
  text-transform: none;
  max-width: 400px;
}

.vitamin-caption {
  padding: 5px;
}

.vitamin-value {
  padding: 14px;
  min-width: 43px;
  background-color: white;
  border-radius: 9px;
  color: #000;
  font-family: 'Roboto';
}

.product__wrapper {
  padding: 30px;
}

.product {
  margin: 20px 8% 20px 8%;
  background-size: contain;
  background-position: center;
  background-color: $white;
  border-radius: 40px;

  h2 {
    margin-top: 15px;
  }

  .product__1 {
    background-size: contain;
    background-position: center;
    background-image: url("../assets/img/bg_product_1.png");
    background-repeat: no-repeat;
    min-height: 18.5vw;
    position: relative;

    .logo {
      width: 20%;
      position: absolute;
      left: 19px;
      top: 50%;
      transform: translateY(-50%);
    }

    .text {
      position: absolute;
      left: 25%;
      top: 50%;
      width: 70%;
      font-size: 1.3vw;
      transform: translateY(-50%);
    }
  }

  .product__2 {
    background-size: contain;
    background-position: center;
    background-image: url("../assets/img/bg_product_2.png");
    background-repeat: no-repeat;
    min-height: 23vw;
    position: relative;

    .logo {
      width: 20%;
      position: absolute;
      left: 19px;
      top: 50%;
      transform: translateY(-50%);
    }

    .text {
      position: absolute;
      left: 25%;
      top: 50%;
      width: 70%;
      font-size: 1.3vw;
      transform: translateY(-50%);
    }
  }

  .product__footer {
    background-size: cover;
    background-position: top center;
    background-image: url("../assets/img/product_footer.png");
    background-repeat: no-repeat;
    min-height: 260px;
    position: relative;
    width: 100%;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;

    .logo {
      width: 36%;
      margin-top: 60px;
      margin-left: 30px;
    }

    .arrows {

      position: absolute;
      right: 4%;
      bottom: 10%;
      max-height: 75%;
    }

    .text_1 {
      position: absolute;
      left: 43%;
      max-width: 34%;
    }
    .text_1 {
      top: 79px;
      font-size: 1.9vw;
    }

    .text_2 {

    }


  }

  .green_text {
    color: #034919;
  }

  .white_text {
    color: #fff;
  }


}

@media screen and (max-width: 992px) {
  .product .product__1 {
    background-color: $orange;
    background-image: none;
    border-radius: 20px;
    text-align: center;
  }

  .product .product__1 .logo {
    width: 35%;
    margin: 15px 0 15px 0;
    position: relative;
    transform: none;
    left:auto;
    top:auto;
  }

  .product .product__1 .text {
    padding: 19px;
    position: relative;
    transform: none;
    width: 93%;
    left: auto;
    top: auto;
    font-size: 16px;
  }

  .vitamin {
    padding: 4px;
    display: inline-block;
    border-radius: 9px;
    margin: 0 2px;
    text-align: center;
    color: #fff;
    font-size: 18px;
  }

  .product .product__2 {
    margin-top: 20px;
    background-color: rgb(30, 177, 237);
    background-image: none;
    border-radius: 20px;
    text-align: center;
  }

  .product .product__2 .logo {
    width: 35%;
    margin: 15px 0 15px 0;
    position: relative;
    transform: none;
    left:auto;
    top:auto;
  }

  .product .product__2 .text {
    padding: 19px;
    position: relative;
    transform: none;
    width: 93%;
    left: auto;
    top: auto;
    font-size: 16px;
  }

  .product__1-image {
    float: none;
    display: block;
    max-height: 400px;
    margin: 0 auto;
    padding-top: 55px;
    padding-bottom: 35px;
  }

  .vitamins {
    position: relative;
    bottom: auto;
    left: auto;
    width: auto;
    margin: 0 auto;
    text-align: center;
  }

  .product__1-title {
    position: absolute;
    margin: auto;
    max-width: 100%;
    font-size: 24px;
    width: 100%;
    text-align: center;
    top: 11px;
  }

  .product__footer {
    text-align: center;
    .logo {
      width: 60%;
      /* margin: 0 auto; */
      margin-left: auto;
    }

    .arrows {

      display: none;
    }

    .text_1 {
      position: relative !important;
      left: auto !important;
      top: auto !important;
      width: 100% !important;
      font-size: 18px !important;
      max-width: 80% !important;
      margin: 0 auto !important;
      padding-bottom: 25px !important;
    }
  }

  .product {
    margin-left: 0px;
    margin-right: 0px;
  }

  .vitamin-value {
    min-width: 0px;
    padding: 6px;
  }
}

</style>

<style>
.agile__slide {
  position: relative;
}
</style>

<style>
.agile__nav-button:hover {
  cursor: pointer;
}

.agile__dots {
  align-items: center;
  display: flex;
  list-style: none;
  padding: 0;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
}

.what-to-do .agile__dots {
  bottom: -160px;
}

.products .agile__dots {
  bottom: -200px;
}

.prizes .agile__dots {
  bottom: -139px
}

.agile__dots {
  bottom: -201px;
}

.agile__dots {
  bottom: -155px;
}

.agile__dot {
  display: block;
  height: 10px;
  width: 10px;
  background-color: #3F3F3F;
  border-radius: 10px;
  transition: width 200ms;
  margin: 0 2.5px;
}

.agile__dot button {
  opacity: 0;
  height: 100%;
  width: 100%;
  color:transparent;
}

.agile__dot--current {
  width: 35px;
}

.descriptor-carousel .agile__nav-button {
  border: none;
  background-color: transparent;
  position: relative;
  height: 50px;
  width: 50px;
  color: transparent;
  margin: 0 5px 0 5px;
}

.descriptor-carousel .agile__nav-button:after {
  content: url('../assets/svg/arrow-yellow.svg');
  position:absolute;
  left: 0;
  top: 0;
}

.descriptor-carousel .agile__nav-button--next {
  transform: rotate(180deg);
}

.descriptor-carousel .agile__actions {
  justify-content: center;
}

.agile__nav-button {
  border: none;
  background-color: transparent;
  position: relative;
  height: 50px;
  width: 50px;
  color: transparent;
  margin: 0 5px 0 5px;
}

.agile__nav-button:after {
  content: url('../assets/svg/arrow-yellow.svg');
  position:absolute;
  left: 0;
  top: 0;
}

.agile__nav-button--next {
  transform: rotate(180deg);
}

.agile__actions {
  justify-content: space-between;
  position: absolute;
  width: 105%;
  bottom: 159px;
  left: -2.5%;
}

@media screen and (max-width: 992px) {

  .agile__actions {
    bottom: 250px;
  }

  .agile__dots {
    bottom: -300px;
  }

}

.products .agile__nav-button {
  border: none;
  background-color: transparent;
  position: relative;
  height: 50px;
  width: 50px;
  color: transparent;
  margin: 0 5px 0 5px;
}

.products .agile__nav-button:after {
  content: url('../assets/svg/arrow-white.svg');
  position:absolute;
  left: 0;
  top: 0;
}

.products .agile__nav-button--next {
  transform: rotate(180deg);
}

.products .agile__actions {
  justify-content: space-around;
  position: absolute;
  width: 100%;
  bottom: 175px;
}

@media screen and (max-width: 992px) {
  .descriptor-carousel .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    width: 100%;
  }

  .what-to-do .agile__nav-button {
    border: none;
    background-color: transparent;
    position: relative;
    height: 50px;
    width: 50px;
    color: transparent;
    margin: 0 5px 0 5px;
  }

  .what-to-do .agile__nav-button:after {
    content: url('../assets/svg/arrow-yellow.svg');
    position:absolute;
    left: 0;
    top: 0;
  }

  .what-to-do .agile__nav-button--next {
    transform: rotate(180deg);
  }

  .what-to-do .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    width: 100%;
  }

  .merch .agile__nav-button {
    border: none;
    background-color: transparent;
    position: relative;
    height: 50px;
    width: 50px;
    color: transparent;
    margin: 0 5px 0 5px;
  }

  .merch .agile__nav-button:after {
    content: url('../assets/svg/arrow-white.svg');
    position:absolute;
    left: 0;
    top: 0;
  }

  .merch .agile__nav-button--next {
    transform: rotate(180deg);
  }

  .merch .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    width: 100%;
  }

  .certificates-carousel .agile__nav-button {
    border: none;
    background-color: transparent;
    position: relative;
    height: 50px;
    width: 50px;
    color: transparent;
    margin: 0 5px 0 5px;
  }

  .certificates-carousel .agile__nav-button:after {
    content: url('../assets/svg/arrow-yellow.svg');
    position:absolute;
    left: 0;
    top: 0;
  }

  .certificates-carousel .agile__nav-button--next {
    transform: rotate(180deg);
  }

  .certificates-carousel .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    width: 100%;
  }

  .prizes-carousel .agile__actions {
    bottom: 50%;
  }

  .products .agile__actions {
    justify-content: space-between;
  }
}

.timer-block {
  color: #fff !important;
  width: 125px;
  height: 125px;
  padding-top: 25px;
  margin: 0 5px 0 5px;
  display: inline-block;
  border: 4px solid #3F3F3F;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 16px;
  text-align: center;
  font-family: "Marvin Round" !important;
}

.timer-number {
  font-size: 26px !important;
  display: block;
}

.timer {
  margin: 15px;
  text-align: center;
}

.timer h2 {
  color:#fff
}

</style>